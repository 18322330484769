import * as React from 'react';
import { useStaticQuery, graphql } from "gatsby";

export default function Partners() {
  const data = useStaticQuery(graphql`
  {
    contentfulHomePage {
      partnerSection {
        subTitle
        title
        titleLink
        logo {
          file {
            url
          }
          title
        }
        description {
          description
        }
        link
        linkName
        linkArrow
      }
    }
  }
`)
  const partner = data.contentfulHomePage.partnerSection;

  return (
    <div className="services-section">
      <div className="auto-container">
        <div className="sec-title">
          <h2>The <span>Implementing</span> Partners</h2>
        </div>
        <div className="inner-container">
          <div className="row g-0">
            {partner.map(({ subTitle, title, titleLink, description, link, linkName, linkArrow, logo }, index) => (
              <div className="service-block col-lg-6 col-md-6 col-sm-12" key={index}>
                <div className="inner-box">
                  <img src={logo.file.url} alt={logo.title} />
                  <div className="sub-title">{subTitle}</div>
                  <h5><a href={titleLink} target="_blank" rel="noreferrer">{title}</a></h5>
                  <div className="text">
                    {description.description}
                  </div>
                  <a className="read-more" href={link} target="_blank" rel="noreferrer">{linkName}<span className={linkArrow} /></a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
