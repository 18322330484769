import styled from 'styled-components';

export const BannerSlider = styled.div`
  height: 86vh;
  background-size: auto;
  background-position: center;
  background-image: url(${props => props.bgImage});
  display: flex;
  align-items: center;
`;
