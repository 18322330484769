import React from 'react';

import ScrollAnimation from 'react-animate-on-scroll';
import SEO from '../components/seo';
import Layout from '../components/layout';
import Banner from '../components/banner/banner';
import Contact from '../components/contact/contact';
import Services from '../components/services/services';
import Partners from '../components/partners/partners';
import { useStaticQuery, graphql } from "gatsby"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulHomePage {
        name
        ctaSection {
          title
          titleSpan
          description {
            description
          }
          linkName
          link
        }
        aboutSection {
          image {
            file {
              url
            }
            title
          }
          title
          description {
            description
          }
        }   
      }
      contentfulMap {
        name
        sourceLink {
          sourceLink
        }
      }
    }
  `)
  const callToAction = data.contentfulHomePage.ctaSection;
  const about = data.contentfulHomePage.aboutSection;
  const aboutImgTitle = data.contentfulHomePage.aboutSection.image;
  const aboutImg = data.contentfulHomePage.aboutSection.image.file;
  const map = data.contentfulMap;
  const mapLink = data.contentfulMap.sourceLink;
  return (
    <Layout>
      <SEO title="Home" />
      <Banner />
      <div className="cta-section">
        <div className="auto-container">
          <div className="row align-items-center">
            <div className="col-lg-7">
              <div className="cta-content">
                <h3 className="title"><span className="text-bold">{callToAction.title}</span>{callToAction.titleSpan}</h3>
                <p>{callToAction.description.description}</p>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="cta-phone text-lg-end text-strat">
                <h2 className="title"><a href={callToAction.link}>{callToAction.linkName}</a></h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="about-section">
        <div className="auto-container">
          <div className="inner-container">
            <div className="row align-items-center clearfix">
              <div className="image-column col-lg-6">
                <ScrollAnimation animateIn="fadeInLeft">
                  <div className="about-image">
                    <div className="about-inner-image">
                      <img alt={aboutImgTitle.title}
                        placeholder="traceSVG"
                        src={aboutImg.url} />
                    </div>
                  </div>
                </ScrollAnimation>
              </div>
              <div className="content-column col-lg-6 col-md-12 col-sm-12 mb-0">
                <ScrollAnimation animateIn="fadeInUp">
                  <div className="about-column">
                    <div className="sec-title">
                      <div className="title">{about.title}</div>
                      <h2>The <span>Program</span> at a Glance</h2>
                    </div>
                    <div className="text">
                      <p>{about.description.description}</p>
                    </div>
                  </div>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Partners />

      <Services />

      <Contact />

      <div className="map-section">
        <div className="contact-map-area-homepage">
          <iframe
            allowFullScreen
            aria-hidden="false"
            className="contact-map"
            title={map.name}
            src={mapLink.sourceLink}
          />
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
