import * as React from 'react';
import { useStaticQuery, graphql } from "gatsby"

const Contact = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulHomePage {
        objectivesAndTargetAudience {
          subtitle
          headlineSpan
          headline
          description
          targetObjectives {
            headline
            description
          }
        }
      }
    }
  `)
  const objective = data.contentfulHomePage.objectivesAndTargetAudience;
  const targets = data.contentfulHomePage.objectivesAndTargetAudience.targetObjectives;

  return (
    <div className="reputation-section-two style-two">
      <div className="auto-container">
        <div className="row clearfix">
          <div className="form-column col-lg-5 col-md-12 col-sm-12">
            <div className="inner-column">
              <div className="form-boxed">
                <h5>Get In Touch</h5>
                <div className="consult-form">
                  <form method="post" action="donate.html">
                    <div className="form-group">
                      <label htmlFor="fullName">full name</label>
                      <input type="text" name="name" placeholder="Full Name" required />
                    </div>
                    <div className="form-group">
                      <label htmlFor="phoneNumber">phone number</label>
                      <input type="text" name="phone" placeholder="Enter your phone number" required />
                    </div>
                    <div className="form-group">
                      <label htmlFor="topics">topics</label>
                      <select className="custom-select-box">
                        <option>Choose topic</option>
                        <option>Job</option>
                        <option>Funds</option>
                        <option>Apprenticeships</option>
                        <option>Enquiries</option>
                      </select>
                    </div>
                    <div className="form-group">
                      <label htmlFor="message">message</label>
                      <textarea name="message" placeholder="Write your message here" />
                    </div>
                    <div className="form-group">
                      <button className="theme-btn btn-style-one" type="submit" name="submit-form"><span className="txt">Submit</span></button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="content-column col-lg-7 col-md-12 col-sm-12">
            { }
            <div className="inner-column">
              <div className="sec-title">
                <div className="title">{objective.subtitle}</div>
                <h2>{objective.headline}<span>{objective.headlineSpan}</span></h2>
                <p>{objective.description}</p>
              </div>
              <div className="blocks-outer">
                {targets.map(({ headline, description }, index) => (
                  <div className="reputation-block" key={index}>
                    <div className="inner-box">
                      <h5>{headline}</h5>
                      <div className="text">{description}</div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Contact;