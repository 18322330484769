import * as React from 'react';
import { useStaticQuery, graphql } from "gatsby";

export default function Services() {
  const data = useStaticQuery(graphql`
    {
      contentfulHomePage {
        discoverSection {
          heading
          headingSpan
          buttonLink
          buttonName
        }
      }
    }
  `)
  const discover = data.contentfulHomePage.discoverSection;
  return (
    <div className="services-section">
      <div className="auto-container">
        <div className="sec-title">
          <h2><span>{discover.headingSpan}</span>{discover.heading}</h2>
        </div>
        <div className="btn-box">
          <a
            target="_blank"
            rel="noreferrer"
            href={discover.buttonLink}
            className="theme-btn btn-style-one">
            <span className="txt">{discover.buttonName}</span>
          </a>
        </div>
      </div>
    </div>
  );
};
