import * as React from 'react';

import Slider from 'react-slick';
import { BannerSlider } from './banner.styles';
import { useStaticQuery, graphql } from "gatsby"


const Banner = () => {
  const data = useStaticQuery(graphql`
  {
    contentfulHomePage {
      sliderSection {
        name
        sliderDescription
        sliderImage {
          file {
            url
          }
          title
        }
        sliderButtonName
      }
    }
  }
`)
  const banner = data.contentfulHomePage.sliderSection;

  const settings = {
    dots: true,
    speed: 500,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="home-banner-section">
      {banner.map(({ name, sliderDescription, sliderImage, sliderButtonName }, index) => (
        <Slider {...settings} key={index}>
          <div>
            <BannerSlider bgImage={sliderImage.file.url}>
              <div className="auto-container w-100">
                <div className="row clearfix">
                  <div className="content-column col-lg-7 col-md-12 col-sm-12">
                    <div className="inner-column">
                      <div className="title">{name}</div>
                      <h1>We are <span>Making it</span> in <br /> The Gambia</h1>
                      <div className="text">{sliderDescription}</div>
                      <div className="btn-box">
                        <a href="about.html" className="theme-btn btn-style-one"><span className="txt">{sliderButtonName}</span></a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </BannerSlider>
          </div>
        </Slider>
      ))}
    </div>
  );
};

export default Banner;
